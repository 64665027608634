import logo from './logo.svg';
import * as React from "react";
import './App.css';
import Home from './component/Pages/Home';
import FooterPart from './component/FooterPart';
import HeadPart from "./component/HeadPart"
import BasePage from './component/BasePage';
import About from './component/Pages/About';
import Analytics from './component/Pages/Analytics';
import { Route, BrowserRouter, Routes } from "react-router-dom";

import ContactUs from './component/Pages/ContactUs';
import Demo from './component/Pages/Demo';
import TechSolution from './component/Pages/TechSolution';
import OurTeam from './component/Pages/OurTeam';
import GrievanceRedressal from './component/Pages/GrievanceRedressal';
import PrivacyPolicy from './component/Pages/Privacy-policy';
import DataPolicy from './component/Pages/Data-policy';
import EmailSend from './component/EmailSend';
import Thank_you from './component/Thank_You';
import Page_Not_Found from './component/Pages/PageNotFound';
import Our_Partners from './component/Pages/Our_Partners';

function App() {
  return (
    <>
      {/* <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
      <HeadPart />
      <FooterPart /> */}
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home /> } />
            <Route path='/about' element={<About />} />
            <Route path='/analytics' element={<Analytics /> } />
            <Route path='/techsoluiton' element={<TechSolution /> } />
            <Route path='/ourTeam' element={<OurTeam /> } />
            <Route path='/contact-us-2' element={<ContactUs /> } />
            <Route path='/demo' element={<Demo /> } />
            <Route path='/grievance-redressal-mechanism' element={<GrievanceRedressal /> } />
            <Route path='/privacy-policy' element={<PrivacyPolicy /> } />
            <Route path='/DataPolicy' element={<DataPolicy /> } />
            <Route path='/EmailSend' element={<EmailSend /> } />
            <Route path='/Thank_you' element={<Thank_you /> } />
            <Route path='/Our_Partners' element={<Our_Partners /> } />
            <Route path="*" element={<Page_Not_Found/>} />
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
