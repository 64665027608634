import axios from "axios";
// import environment from "../env.json";

export const Custom_Data = async (requestOptions, Token) => {
    return new Promise(async (resolve, reject) => {
        const data = requestOptions;
        const headers = {
            'X-Api-Key': "2V7I5AVIbZ4ZL8jTiN1HG9EpyQuafteDaFa6gP1g",
            'Content-Type': 'application/json',
        }
        
        await axios.post(`https://gsg8hi850j.execute-api.ap-south-1.amazonaws.com/Production/api/v1/service`, data,{
            headers: headers
        })
        .then(function (response) {
            if (response && response.data && response.data.data) {
                console.log(" response.data---", response);
                resolve(response.data);
            }
            else {
                resolve([]);
            }
            
        })
        .catch(function (error) {
            console.log( " reject =========== ", error);
            resolve([]);
            // reject(false);
        });
    });
}