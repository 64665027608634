import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const FooterPart = () => {
    return (
        <div>
            <footer id="footer" className="align-left light ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <a href="https://www.acennacollect.in/auth/login" target="_blank">
                            <button type="button" className="btn btn-primary acenna">PANEL LOGIN</button></a>

                            <a href="https://drive.google.com/file/d/11ghZFZ6XZ-BWT3hzPLD20QUwBnxwaCPn/view?usp=drive_link" target="_blank">
                                <button type="button" className="btn btn-primary acenna">Android App Download</button></a>
                            {/* <p className="andrdapdwnld">Android App Download</p> */}

                            <a className="googlebtn" 
                            target="_blank" 
                            // href="https://play.google.com/store/apps/details?id=com.acenna.employee"
                            >
                            {/* <h3>App Download</h3> */}
                            {/* <img src="wp-content/uploads/2019/06/google-play-badge.png" /> */}
                            </a>

                            <a href="https://www.linkedin.com/company/acenna-technology-solution-pvt-ltd/?trk=my-network_organization_details" target="_blank">
                                <button type="button" className="btn btn-primary acenna"><p id="Linkedin123" >Linked<div ><img id="Linkedinlogo123" src="wp-content/uploads/2019/06/resizelinkdin4.png"/></div></p>
                                    
                                </button>
                                </a>
                            {/* <div className="icon-links icon-social icon-links-grid social-colors"><a className="linkedin" target="_blank"  
                            href="https://www.linkedin.com/company/acenna-technology-solution-pvt-ltd/?trk=my-network_organization_details">
                            <i className="icon-linkedin" />
                            </a></div> */}
                        </div>
                        <div className="col-lg-3">
                            <h3>Why Acenna?</h3>
                            <ul className="icon-list icon-line">
                                <li><Link className="fhover" to="/ourTeam">Experienced Team</Link></li>
                                <li><Link to="/techsoluiton">Technology Led Collection</Link></li>
                                {/* <HashLink smooth to="/analytics#column_387JF">Extensive Field Presence1</HashLink> */}
                                <li><HashLink smooth to="/analytics#column_387JF">Extensive Field Presence</HashLink></li>
                                <li><HashLink smooth to="/analytics#column_b278o">Customized Segmentation</HashLink></li>
                                <li><HashLink smooth to="/analytics#column_AbobK">Analytics Driven Campaigns</HashLink></li>
                            </ul>
                        </div>
                        <div className="col-lg-5">
                            <ul className="text-list text-list-line">
                                <li><b>Registered Address</b>
                                    <hr />
                                    <p>2A, Lentin Chambers,
                                        <br />Fort, Mumbai 400001</p>
                                </li><br />
                                <li><b>Operational Address</b>
                                    <hr />
                                    <p>6th Flr, Tower B, Arihant Aura,<br />Turbhe, Navi Mumbai -- 400703</p>
                                </li><br />
                                <li><b>Email</b>
                                    <hr />
                                    <p><a href="mailto:info@acenna.in" className="__cf_email__" >info@acenna.in</a></p>
                                </li>
                                <li><b>Phone</b>
                                    <hr />
                                    <p>022-22657214</p>
                                </li>
                                <li><b>Opening Hours</b>
                                    <hr />
                                    <p>9AM - 7PM Mon - Sat</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bar">
                    <div className="container"><span id="content1">© 2023 Acenna Technology Solutions Pvt. Ltd.</span><span className="content2"><a href="/privacy-policy">Privacy
                        policy</a> | <a href="/DataPolicy">Data
                        policy</a> | <a href="/grievance-redressal-mechanism">Grievance Redressal
                            Mechanism</a> | <a href="/Our_Partners">Partners</a></span></div>
                </div>
            </footer>
        </div>
    )
}

export default FooterPart;